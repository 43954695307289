import Rails from '@rails/ujs';
import { Turbo } from '@hotwired/turbo-rails';
import 'alpine-turbo-drive-adapter';
import 'alpinejs';
import 'sharer.js';
import 'balloon-css';
import $ from 'jquery';

import * as Lib from '../Libs/application';

// JS Components
import '../components/becomeMember';
import '../components/clipboard';
import '../components/newsletter';
import '../components/subscriptionCheck';
import '../controllers/application';
import '../stylesheets/tailwind.css';
import '../stylesheets/application.css';
import '../stylesheets/vendors/slick.css';

global.$ = $;

window.Turbo = Turbo;

require.context('../images', true);
Rails.start();
const { delegate, disableElement, enableElement } = Rails;

delegate(document, Rails.formSubmitSelector, 'turbo:submit-start', disableElement);
delegate(document, Rails.formSubmitSelector, 'turbo:before-cache', enableElement);

document.addEventListener('turbo:load', () => {
  const $container = $(document);
  Lib.SlickCarousel($container);

  if (window.Sharer) window.Sharer.init();
  if (window.HubSpotConversations) window.HubSpotConversations.resetAndReloadWidget();

  window.Alpine.discoverUninitializedComponents((el) => {
    window.Alpine.initializeComponent(el);
  });
});
