import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['replyForm', 'replyFormBodyInput'];

  initialize() {
    this.boundHandleKeyDown = this.handleKeyDown.bind(this);
    this.boundHandleInput = this.handleInput.bind(this);
  }

  connect() {
    $(this.replyFormBodyInputTarget).on('keydown', this.boundHandleKeyDown);
    $(this.replyFormBodyInputTarget).on('input', this.boundHandleInput);
  }

  disconnect() {
    $(this.replyFormBodyInputTarget).off('keydown', this.boundHandleKeyDown);
    $(this.replyFormBodyInputTarget).off('input', this.boundHandleInput);
  }

  handleKeyDown(e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      $(this.replyFormBodyInputTarget).val(e.target.value.trim())
      if (e.target.value.trim()) {
        $(this.replyFormTarget).find("button[type=submit]").trigger('click');
      } else {
        this.addError();
      }

      e.preventDefault();
    }
  }

  handleInput(e) {
    if (e.target.value && this.error) this.removeError();
  }


  show_reply_form() {
    this.hide_reply_form();
    $(this.replyFormTarget).removeClass('hidden');
    $(this.replyFormBodyInputTarget).focus();
  }

  hide_reply_form() {
    var forms = $(this.replyFormTarget).parents('#comments').find('[data-comment-target="replyForm"]');

    forms.addClass('hidden');
    forms.find('[data-comment-target="replyFormBodyInput"]').val('');
    this.replyFormBodyInputTarget.style.height = '52px';
  }

  addError() {
    if (this.error) return;
    this.error = true;
    $(this.replyFormBodyInputTarget).closest('.form__group').append($("<div class='form__error'>can't be blank</div>"));
    $(this.replyFormBodyInputTarget).addClass('form__input-errored');
  }

  removeError() {
    if (!this.error) return;
    this.error = false;
    $(this.element).find('.form__error').remove();
    $(this.replyFormBodyInputTarget).removeClass('form__input-errored');
  }
}
