import Rails from '@rails/ujs';
import $ from 'jquery';
import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ['element'];

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: '.handle',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let data = new FormData()
    let url = this.data.get("url")

    this.elementTargets.map((element) => {
      const id = $(element).data("id");
      data.append("ids[]", id)
    })

    Rails.ajax({
      url,
      type: 'PUT',
      data
    })
  }
}
