import $ from 'jquery';

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SlickCarousel($container) {
  const $slick = $container.find('.js-carousel');
  // const $slides = $container.find('.js-carouselSlide');

  $slick.slick({
    infinite: true,
    dots: true,
    vertical: true,
    prevArrow: false,
    centerMode: true,
    nextArrow: false,
    centerPadding: 0,
    adaptiveHeight: true,

    // speed: 200,
    // fade: true,
    // cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 3000,
    // pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          vertical: false,
        },
      },
    ],
  });
}

export default SlickCarousel;
