import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['commentForm', 'commentFormBodyInput'];

  initialize() {
    this.boundHandleKeyDown = this.handleKeyDown.bind(this);
    this.boundHandleInput = this.handleInput.bind(this);
  }

  connect() {
    $(this.commentFormBodyInputTarget).on('keydown', this.boundHandleKeyDown);
    $(this.commentFormBodyInputTarget).on('input', this.boundHandleInput);
  }

  disconnect() {
    $(this.commentFormBodyInputTarget).off('keydown', this.boundHandleKeyDown);
    $(this.commentFormBodyInputTarget).off('input', this.boundHandleInput);
  }

  handleKeyDown(e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      $(this.commentFormBodyInputTarget).val(e.target.value.trim())
      if (e.target.value.trim()) {
        $(this.commentFormTarget).find("button[type=submit]").trigger('click');
      } else {
        this.addError();
      }

      e.preventDefault();
    }
  }

  handleInput(e) {
    if (e.target.value && this.error) this.removeError();
  }


  clearCommentForm() {
    $(this.commentFormBodyInputTarget).val('');
    $(this.commentFormTarget).find(".form__error").remove();
    this.commentFormBodyInputTarget.style.height = '52px';
  }

  addError() {
    if (this.error) return;
    this.error = true;
    $(this.commentFormBodyInputTarget).closest('.form__group').append($("<div class='form__error'>can't be blank</div>"));
    $(this.commentFormBodyInputTarget).addClass('form__input-errored');
  }

  removeError() {
    if (!this.error) return;
    this.error = false;
    $(this.element).find('.form__error').remove();
    $(this.commentFormBodyInputTarget).removeClass('form__input-errored');
  }
}
