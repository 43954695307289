import Rails from '@rails/ujs';
import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "authorType", "authorTypeAlert", "fromUserForm", "authorForm", "buttonsBlock", "userAvatar",
                     "userId", "authorAvatarTemplate", "authorAvatarField", "authorField", "textArea", "bio" ]

  static values = { avatar: String, id: String }

  initialize() {
    this.boundHandleUserChange = this.handleUserChange.bind(this);
    this.boundHandleAutorTypeChange = this.handleAuthorTypeChange.bind(this);
    this.boundHandleResizeTextArea = this.handleResizeTextArea.bind(this);
    this.boundHandleFileChosen = this.handleFileChosen.bind(this);
    this.textAreaTargets.forEach((target) => this.handleResizeTextArea({ target }));
    this.handleAuthorTypeChange(null);
  }

  connect() {
    $(this.userIdTarget).on('change', this.boundHandleUserChange);
    $(this.authorTypeTarget).on('change', this.boundHandleAutorTypeChange);
    $(this.textAreaTargets).on('input', this.boundHandleResizeTextArea);
    $(this.authorAvatarFieldTarget).on('change', this.boundHandleFileChosen);
  }

  disconnect() {
    $(this.userIdTarget).off('change', this.boundHandleUserChange);
    $(this.authorTypeTarget).on('change', this.boundHandleAutorTypeChange);
    $(this.textAreaTargets).off('input', this.boundHandleResizeTextArea);
    $(this.authorAvatarFieldTarget).off('change', this.boundHandleFileChosen);
  }

  get userId() {
    return this.userIdTarget.value;
  }

  get authorType() {
    return this.authorTypeTarget.value;
  }

  handleAuthorTypeChange(e) {

    if (this.idValue) return;

    if (e) {
      this.clearErrors();
      this.resetAvatar();
    }

    // TODO: improve existing values
    if (this.authorType == 'Existing User')  {
      this.fromUserFormTarget.classList.remove("hidden")
      this.authorFormTarget.classList.add("hidden")
      this.buttonsBlockTarget.classList.remove("hidden")
      this.bioTarget.classList.remove("hidden")
      this.authorTypeAlertTarget.classList.add("hidden")
      this.clearAuthorFields();
    } else if (this.authorType == 'Third-party Author') {
      this.fromUserFormTarget.classList.add("hidden")
      this.authorFormTarget.classList.remove("hidden")
      this.buttonsBlockTarget.classList.remove("hidden")
      this.bioTarget.classList.remove("hidden")
      this.authorTypeAlertTarget.classList.add("hidden")
      this.clearUserFields();
    } else {
      this.fromUserFormTarget.classList.add("hidden")
      this.authorFormTarget.classList.add("hidden")
      this.buttonsBlockTarget.classList.add("hidden")
      this.bioTarget.classList.add("hidden")
      this.authorTypeAlertTarget.classList.remove("hidden")
      this.clearAuthorFields();
      this.clearUserFields();
    }

    this.textAreaTargets.forEach((target) => this.handleResizeTextArea({ target }));
  }

  clearUserFields() {
    // TODO: default avatar
    $(this.userAvatarTarget).attr("src", this.avatarValue);
    if ($(this.userIdTarget)[0].selectize) $(this.userIdTarget)[0].selectize.clear();
  }

  clearAuthorFields() {
    this.authorFieldTargets.map((el) => el.value = '');
  }

  handleUserChange() {
    if (this.userId) {
      this.loadUserAvatar();
    }
  }

  loadUserAvatar() {
    Rails.ajax({
      url: `/admin/users/${this.userId}/avatar_url`,
      type: 'get',
      data: "",
      success: (src) => $(this.userAvatarTarget).attr("src", src),
    })
  }

  handleResizeTextArea(e) {
    if (!e.target) return;
    e.target.style.height = '90px';
    e.target.style.height = e.target.scrollHeight + 'px';
  }

  resetTextArea() {
    this.textAreaTargets.forEach((target) => (target.style.height = '90px'));
  }

  clearErrors() {
    $(this.element).find('.form__error').remove();
    $(this.element).find('.field_with_errors').removeClass('field_with_errors');
    $(this.element).find('.form__input-errored').removeClass('form__input-errored');
  }

  handleFileChosen(event) {
    if (!event.target.files.length) return;

    const file = event.target.files[0];

    if (file.size / 1024 / 1024 > 5) {
      window.alert('The file is too large. Max size - 5MB.');
      event.target.value = '';
      return;
    }
    const reader = new FileReader();
    const callback = (res) => {
      $(this.authorAvatarTemplateTarget).attr("src", res);
    }

    reader.readAsDataURL(file);
    reader.onload = (e) => callback(e.target.result);
  }

  resetAvatar() {
    $(this.authorAvatarTemplateTarget).attr("src", this.avatarValue);
  }
}
