import Rails from '@rails/ujs';
import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static values = { size: String }

  initialize() {
    this.boundHandleResize = this.handleResize.bind(this);
    this.handleResize();
  }

  connect() {
    $(this.element).on('input', this.boundHandleResize);
  }

  disconnect() {
    $(this.element).off('input', this.boundHandleResize);
  }

  handleResize() {
    this.element.style.height = this.sizeValue || '90px';
    this.element.style.height = `${this.element.scrollHeight}px`;
  }
}
