import Rails from '@rails/ujs';
import { Controller } from 'stimulus';
import $ from 'jquery';

const event = new Event('input', {
  bubbles: true,
  cancelable: true,
});

export default class extends Controller {
  static values = { name: String }

  initialize() {
    this.boundHandleTargetInput = this.handleTargetInput.bind(this);
  }

  connect() {
    $(`[name="${this.nameValue}"]`).on('input', this.boundHandleTargetInput);
  }

  disconnect() {
    $(`[name="${this.nameValue}"]`).off('input', this.boundHandleTargetInput);
  }

  handleTargetInput(e) {
    $(this.element).val(e.target.value);
    this.element.dispatchEvent(event);
  }
}
