window.subscriptionCheck = {
  isLoading: false,
  isSubscribed: false,
  isError: false,

  reset() {
    this.isLoading = false;
    this.isSubscribed = false;
    this.isError = false;
  },

  check(e) {
    const email = e.target.value;
    if (!email) {
      this.reset();
      return;
    }

    this.isLoading = true;
    this.isError = false;
    const params = new URLSearchParams({ email });
    const url = `/memberships/check?${params}`;

    window.fetch(url)
      .then((res) => res.json())
      .then(({ subscribed }) => {
        this.isSubscribed = subscribed;
        this.isError = false;
        this.isLoading = false;
      })
      .catch(() => {
        this.isSubscribed = false;
        this.isLoading = false;
        this.isError = true;
      });
  },
};
