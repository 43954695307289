import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.boundHandleInput = this.handleInput.bind(this);
    this.boundHandleKeyDown = this.handleKeyDown.bind(this);
    this.boundRemoveError = this.removeError.bind(this);
  }

  connect() {
    $(this.element).find('.form__input').on('input', this.boundHandleInput);
    $(this.element).find('.form__input').on('keydown', this.boundHandleKeyDown);
    $(this.element).find('.form__input').on('blur', this.boundRemoveError);
  }

  disconnect() {
    $(this.element).find('.form__input').off('input', this.boundHandleInput);
    $(this.element).find('.form__input').off('keydown', this.boundHandleKeyDown);
    $(this.element).find('.form__input').off('blur', this.boundRemoveError);
  }

  handleInput(e) {
    if (e.target.value && this.error) this.removeError();
  }

  handleKeyDown(e) {
    if (e.keyCode === 13 && !e.target.value) {
      this.addError();
      e.preventDefault();
    }
  }

  addError() {
    if (this.error) return;
    this.error = true;
    $(this.element).append($("<div class='form__error'>can't be blank</div>"));
    $(this.element).find('.form__input').addClass('form__input-errored');
  }

  removeError() {
    if (!this.error) return;
    this.error = false;
    $(this.element).find('.form__error').remove();
    $(this.element).find('.form__input').removeClass('form__input-errored');
  }
}
